define("@fleetbase/console/helpers/smart-humanize", ["exports", "@fleetbase/console/utils/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function smartHumanize(_ref) {
    let [string] = _ref;
    const uppercase = ['api', 'vat', 'id', 'sku'];
    return (0, _humanize.default)(Ember.String.decamelize(string)).toLowerCase().split(' ').map(word => {
      if (uppercase.includes(word)) {
        return word.toUpperCase();
      }

      return Ember.String.capitalize(word);
    }).join(' ');
  });

  _exports.default = _default;
});