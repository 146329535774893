define("@fleetbase/console/helpers/avatar-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function avatarUrl(_ref) {
    let [url, defaultUrl = 'https://s3.ap-southeast-1.amazonaws.com/flb-assets/static/no-avatar.png'] = _ref;

    if (typeof url === 'string') {
      return url;
    }

    return defaultUrl;
  });

  _exports.default = _default;
});