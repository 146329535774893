define("@fleetbase/console/components/trial-status-badge", ["exports", "@glimmer/component", "date-fns"], function (_exports, _component, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="trial-status-badge" {{did-insert (fn this.setupComponent)}} ...attributes>
      {{#if this.shouldDisplay}}
          <a href="javascript:;" class="rounded-lg px-2 py-0.5 flex flex-row border {{this.borderColorScheme}} {{this.bgColorScheme}} truncate shadow-sm hover:bg-opacity-50 {{@badgeClass}}" {{on "click" (fn this.onClick)}}>
              {{#if this.isTrialExpired}}
                  <FaIcon @icon="alarm-exclamation" class="{{this.textColorScheme}} mr-1.5" />
                  <span class="{{this.textColorScheme}} font-semibold text-xs">
                      Your trial has expired!
                  </span>
              {{/if}}
              {{#if this.isTrialing}}
                  <FaIcon @icon="bell" class="{{this.textColorScheme}} mr-1.5" />
                  <span class="{{this.textColorScheme}} font-semibold text-xs">
                      {{this.remaining}} remaining in trial
                  </span>
              {{/if}}
          </a>
      {{/if}}
  </div>
  */
  {
    "id": "WSlsLHIR",
    "block": "{\"symbols\":[\"@badgeClass\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"trial-status-badge\"],[17,2],[4,[38,3],[[30,[36,0],[[32,0,[\"setupComponent\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"shouldDisplay\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"a\"],[24,6,\"javascript:;\"],[16,0,[31,[\"rounded-lg px-2 py-0.5 flex flex-row border \",[32,0,[\"borderColorScheme\"]],\" \",[32,0,[\"bgColorScheme\"]],\" truncate shadow-sm hover:bg-opacity-50 \",[32,1]]]],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"onClick\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isTrialExpired\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"fa-icon\",[[16,0,[31,[[32,0,[\"textColorScheme\"]],\" mr-1.5\"]]]],[[\"@icon\"],[\"alarm-exclamation\"]],null],[2,\"\\n                \"],[10,\"span\"],[15,0,[31,[[32,0,[\"textColorScheme\"]],\" font-semibold text-xs\"]]],[12],[2,\"\\n                    Your trial has expired!\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,0,[\"isTrialing\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"fa-icon\",[[16,0,[31,[[32,0,[\"textColorScheme\"]],\" mr-1.5\"]]]],[[\"@icon\"],[\"bell\"]],null],[2,\"\\n                \"],[10,\"span\"],[15,0,[31,[[32,0,[\"textColorScheme\"]],\" font-semibold text-xs\"]]],[12],[2,\"\\n                    \"],[1,[32,0,[\"remaining\"]]],[2,\" remaining in trial\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\",\"did-insert\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/trial-status-badge.hbs"
    }
  });

  let TrialStatusBadgeComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed('isTrialExpired'), _dec12 = Ember.computed('isTrialExpired'), _dec13 = Ember.computed('isTrialExpired'), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class TrialStatusBadgeComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "status", _descriptor3, this);

      _initializerDefineProperty(this, "shouldDisplay", _descriptor4, this);

      _initializerDefineProperty(this, "isSubscribed", _descriptor5, this);

      _initializerDefineProperty(this, "isTrialing", _descriptor6, this);

      _initializerDefineProperty(this, "isTrialExpired", _descriptor7, this);

      _initializerDefineProperty(this, "remaining", _descriptor8, this);

      _initializerDefineProperty(this, "trialExpiresAt", _descriptor9, this);

      _initializerDefineProperty(this, "duration", _descriptor10, this);
    }

    get bgColorScheme() {
      const {
        isTrialExpired
      } = this;

      if (isTrialExpired) {
        return 'bg-red-100';
      }

      return 'bg-yellow-100';
    }

    get borderColorScheme() {
      const {
        isTrialExpired
      } = this;

      if (isTrialExpired) {
        return 'border-red-300';
      }

      return 'border-yellow-300';
    }

    get textColorScheme() {
      const {
        isTrialExpired
      } = this;

      if (isTrialExpired) {
        return 'text-red-600';
      }

      return 'text-yellow-600';
    }

    setupComponent() {
      this.fetchSubscriptionStatus();
    }

    onClick() {
      this.router.transitionTo('console.account.billing');
    }

    setStatus() {
      let status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const {
        is_subscribed,
        is_trialing,
        trial_expires_at
      } = status;
      const trialExpiresAt = new Date(trial_expires_at);
      this.status = status;
      this.isSubscribed = is_subscribed === true;
      this.isTrialing = is_trialing === true;
      this.isTrialExpired = is_trialing === false && is_subscribed === false;
      this.shouldDisplay = is_subscribed === false || is_trialing === true;

      if ((0, _dateFns.isValid)(trialExpiresAt)) {
        const duration = (0, _dateFns.intervalToDuration)({
          start: new Date(),
          end: trialExpiresAt
        });
        this.trialExpiresAt = trialExpiresAt;
        this.duration = duration;
        this.remaining = (0, _dateFns.formatDuration)(duration, {
          format: ['years', 'months', 'days']
        });
      }
    }

    fetchSubscriptionStatus() {
      return this.fetch.get('billing/check-subscription', {}, {
        expirationInterval: 1
      }).then(this.setStatus);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "shouldDisplay", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSubscribed", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isTrialing", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isTrialExpired", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "remaining", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "trialExpiresAt", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "duration", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bgColorScheme", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "bgColorScheme"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "borderColorScheme", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "borderColorScheme"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "textColorScheme", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "textColorScheme"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupComponent", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setupComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setStatus", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchSubscriptionStatus", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "fetchSubscriptionStatus"), _class.prototype)), _class));
  _exports.default = TrialStatusBadgeComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TrialStatusBadgeComponent);
});