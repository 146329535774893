define("@fleetbase/console/models/fleet", ["exports", "@ember-data/model", "date-fns", "@fleetbase/console/utils/auto-serialize"], function (_exports, _model, _dateFns, _autoSerialize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Fleet = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.belongsTo)('service-area'), _dec7 = (0, _model.belongsTo)('zone'), _dec8 = (0, _model.hasMany)('driver'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('date'), _dec18 = (0, _model.attr)('date'), _dec19 = (0, _model.attr)('date'), _dec20 = Ember.computed('updated_at'), _dec21 = Ember.computed('updated_at'), _dec22 = Ember.computed('updated_at'), _dec23 = Ember.computed('created_at'), _dec24 = Ember.computed('created_at'), _dec25 = Ember.computed('created_at'), (_class = class Fleet extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "public_id", _descriptor, this);

      _initializerDefineProperty(this, "company_uuid", _descriptor2, this);

      _initializerDefineProperty(this, "image_uuid", _descriptor3, this);

      _initializerDefineProperty(this, "service_area_uuid", _descriptor4, this);

      _initializerDefineProperty(this, "zone_uuid", _descriptor5, this);

      _initializerDefineProperty(this, "service_area", _descriptor6, this);

      _initializerDefineProperty(this, "zone", _descriptor7, this);

      _initializerDefineProperty(this, "drivers", _descriptor8, this);

      _initializerDefineProperty(this, "drivers_count", _descriptor9, this);

      _initializerDefineProperty(this, "drivers_online_count", _descriptor10, this);

      _initializerDefineProperty(this, "photo_url", _descriptor11, this);

      _initializerDefineProperty(this, "name", _descriptor12, this);

      _initializerDefineProperty(this, "color", _descriptor13, this);

      _initializerDefineProperty(this, "task", _descriptor14, this);

      _initializerDefineProperty(this, "status", _descriptor15, this);

      _initializerDefineProperty(this, "slug", _descriptor16, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor17, this);

      _initializerDefineProperty(this, "created_at", _descriptor18, this);

      _initializerDefineProperty(this, "updated_at", _descriptor19, this);
    }

    /** @computed */
    get updatedAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }

    get updatedAt() {
      return (0, _dateFns.format)(this.updated_at, 'PPP p');
    }

    get updatedAtShort() {
      return (0, _dateFns.format)(this.updated_at, 'PP');
    }

    get createdAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }

    get createdAt() {
      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }

    get createdAtShort() {
      return (0, _dateFns.format)(this.created_at, 'PP');
    }

    toJSON() {
      return (0, _autoSerialize.default)(this, ['serviceArea', 'drivers']);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "image_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "service_area_uuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "zone_uuid", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "service_area", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "zone", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "drivers", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "drivers_count", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "drivers_online_count", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "photo_url", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "color", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "task", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAtShort", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtShort", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtShort"), _class.prototype)), _class));
  _exports.default = Fleet;
});