define("@fleetbase/console/utils/is-waypoint-record", ["exports", "@fleetbase/console/models/waypoint"], function (_exports, _waypoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isWaypointRecord;

  function isWaypointRecord(record) {
    return record instanceof _waypoint.default;
  }
});