define("@fleetbase/console/components/cell/order-details", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "grcallPv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/cell/order-details.hbs"
    }
  });

  class CellOrderDetailsComponent extends _component.default {}

  _exports.default = CellOrderDetailsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CellOrderDetailsComponent);
});