define("@fleetbase/console/helpers/count-keys", ["exports", "storefront/helpers/count-keys"], function (_exports, _countKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "countKeys", {
    enumerable: true,
    get: function () {
      return _countKeys.countKeys;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _countKeys.default;
    }
  });
});