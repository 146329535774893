define("@fleetbase/console/utils/api-url", ["exports", "@fleetbase/console/utils/console-url", "@fleetbase/console/config/environment"], function (_exports, _consoleUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = apiUrl;

  function apiUrl(path) {
    let queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let subdomain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'api';
    let host = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'fleetbase.io';

    if (['local', 'development'].includes(_environment.default.environment)) {
      subdomain = 'v2api';
      host = 'fleetbase.engineering';
    }

    return (0, _consoleUrl.default)(path, queryParams, subdomain, host);
  }
});