define("@fleetbase/console/utils/to-leaflet-bounds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = toLeafletBounds;

  function toLeafletBounds(a, b) {
    if (!a || a instanceof L.Bounds) {
      return a;
    }

    return new L.Bounds(a, b);
  }
});