define("@fleetbase/console/serializers/waypoint", ["exports", "@fleetbase/console/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WaypointSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        place: {
          embedded: 'always'
        },
        tracking_number: {
          embedded: 'always'
        }
      };
    }
    /**
     * Normalizes a part of the JSON payload returned by the server.
     *
     * @method normalize
     * @param {Model} modelClass
     * @param {Object} resourceHash
     * @param {String} prop
     * @return {Object}
     */


    normalize(model, hash, prop) {
      // if waypoint is being loaded from a place model,
      // alias the attributes into the place belongsTo relationship
      if (typeof (hash === null || hash === void 0 ? void 0 : hash.id) === 'string' && hash.id.startsWith('place_')) {
        Ember.setProperties(hash, {
          place: {
            public_id: hash.id,
            ...Ember.getProperties(hash, ['uuid', 'name', 'address', 'address_html', 'location', 'street1', 'street2', 'city', 'province', 'country', 'postal_code', 'phone', 'security_access_code', 'building', 'district', 'neighhborhood'])
          }
        });
      }

      return super.normalize(model, hash, prop);
    }

  }

  _exports.default = WaypointSerializer;
});