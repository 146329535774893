define("@fleetbase/console/services/crud", ["exports", "ember-inflector", "@fleetbase/console/utils/get-model-name", "@fleetbase/console/utils/humanize", "@fleetbase/console/utils/first", "moment"], function (_exports, _emberInflector, _getModelName, _humanize, _first, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CrudService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class CrudService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    /**
     * Closes a current modal then opens another modal action
     *
     * @void
     */
    next() {
      const args = [...arguments];
      const nextAction = args[0]; // shift off the action

      args.shift();
      this.modalsManager.done().then(() => {
        if (typeof this[nextAction] === 'function') {
          this[nextAction](...args);
        }
      });
    }
    /**
     * Generic deletion modal with options
     *
     * @param {Model} model
     * @param {Object} options
     * @void
     */


    delete(model) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const modelName = (0, _getModelName.default)(model);
      this.modalsManager.confirm({
        title: `Are you sure to delete this ${options.modelName || (0, _humanize.default)(modelName).toLowerCase()}?`,
        args: ['model'],
        model,
        confirm: modal => {
          modal.startLoading();
          return model.destroyRecord().then(model => {
            this.notifications.success(options.successNotification || `'${options.modelName || model.name || (0, _humanize.default)(modelName)}' has been deleted.`);
          });
        },
        ...options
      });
    }
    /**
     * Generic deletion modal with options
     *
     * @param {Array} selected an array of selected models for deletion
     * @param {Object} options
     * @void
     */


    bulkDelete(selected) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!Ember.isArray(selected) || selected.length === 0) {
        return;
      }

      const firstModel = (0, _first.default)(selected);
      const modelName = (0, _getModelName.default)(firstModel); // make sure all are the same type

      selected = selected.filter(m => (0, _getModelName.default)(m) === modelName);
      return this.bulkAction('delete', selected, {
        acceptButtonScheme: 'danger',
        acceptButtonIcon: 'trash',
        actionPath: `${Ember.String.dasherize((0, _emberInflector.pluralize)(modelName))}/bulk-delete`,
        actionMethod: `DELETE`,
        ...options
      });
    }
    /**
     * Generic bulk action on multiple models modal with options
     *
     * @param {Array} selected an array of selected models for deletion
     * @param {Object} options
     * @void
     */


    bulkAction(verb, selected) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (!Ember.isArray(selected) || selected.length === 0) {
        return;
      }

      const firstModel = (0, _first.default)(selected);
      const modelName = (0, _getModelName.default)(firstModel);
      const count = selected.length;
      const actionMethod = (typeof options.actionMethod === 'string' ? options.actionMethod : `POST`).toLowerCase();
      this.modalsManager.show('modals/bulk-action-model', {
        title: `Bulk ${verb} ${(0, _emberInflector.pluralize)(modelName)}`,
        acceptButtonText: (0, _humanize.default)(verb),
        args: ['selected'],
        modelNamePath: 'name',
        verb,
        selected,
        count,
        modelName,
        remove: model => {
          selected.removeObject(model);
          this.modalsManager.setOption('selected', selected);
        },
        confirm: modal => {
          const selected = modal.getOption('selected');
          modal.startLoading();
          return this.fetch[actionMethod](options.actionPath, {
            ids: selected.map(model => model.id)
          }).then(response => {
            this.notifications.success(response.message || options.successNotification || `${count} ${(0, _emberInflector.pluralize)(modelName, count)} were updated successfully.`);
          });
        },
        ...options
      });
    }
    /**
     * Toggles dialog to export resource data
     *
     * @void
     */


    export(modelName) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // always lowercase modelname
      modelName = modelName.toLowerCase(); // set the model uri endpoint

      const modelEndpoint = Ember.String.dasherize((0, _emberInflector.pluralize)(modelName));
      this.modalsManager.show('modals/export-form', {
        title: `Export ${(0, _emberInflector.pluralize)(modelName)}`,
        acceptButtonText: 'Download',
        formatOptions: ['csv', 'xlsx', 'xls', 'html', 'pdf'],
        setFormat: _ref => {
          let {
            target
          } = _ref;
          this.modalsManager.setOption('format', target.value || null);
        },
        confirm: (modal, done) => {
          const format = modal.getOption('format') || 'xlsx';
          modal.startLoading();
          return this.fetch.download(`${modelEndpoint}/export`, {
            format
          }, {
            fileName: `${modelEndpoint}-${(0, _moment.default)().format('YYYY-MM-DD-HH:mm')}.${format}`
          }).then(() => {
            setTimeout(() => {
              return done();
            }, 600);
          }).catch(error => {
            modal.stopLoading();
            this.notifications.serverError(error, 'Unable to download API credentials export.');
          });
        },
        ...options
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "next", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bulkDelete", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "bulkDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bulkAction", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "bulkAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "export", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "export"), _class.prototype)), _class));
  _exports.default = CrudService;
});