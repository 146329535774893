define("@fleetbase/console/utils/make-dataset", ["exports", "@fleetbase/console/utils/group-by", "@fleetbase/console/utils/range", "moment"], function (_exports, _groupBy, _range2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = makeDataset;
  _exports.makeMockDataset = makeMockDataset;
  _exports.randomDateThisMonth = randomDateThisMonth;
  _exports.randomInt = randomInt;
  Object.defineProperty(_exports, "range", {
    enumerable: true,
    get: function () {
      return _range2._range;
    }
  });

  function randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  function randomDateThisMonth() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), randomInt(0, 29), randomInt(0, 60));
  }

  function makeMockDataset(start, end) {
    let dateProperty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'created_at';
    const data = (0, _range2._range)(start, end).map(() => {
      return {
        created_at: randomDateThisMonth()
      };
    });
    const grouped = (0, _groupBy.default)(data, record => {
      return (0, _moment.default)(new Date(record[dateProperty])).format('MMMM, DD YYYY');
    });
    const dataset = [];

    for (let day in grouped) {
      dataset.pushObject({
        t: new Date(`${day} 00:00:00`),
        y: grouped[day].length
      });
    }

    return dataset.sortBy('t');
  }

  function makeDataset(recordArray) {
    let filter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Boolean;
    let dateProperty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'created_at';
    const filteredData = recordArray.filter(filter);
    const grouped = (0, _groupBy.default)(filteredData, record => {
      return (0, _moment.default)(new Date(record[dateProperty])).format('MMMM, DD YYYY');
    });
    const dataset = [];

    for (let day in grouped) {
      dataset.pushObject({
        t: new Date(`${day} 00:00:00`),
        y: grouped[day].length
      });
    }

    return dataset.sortBy('t');
  }
});