define("@fleetbase/console/helpers/is-electron", ["exports", "@fleetbase/console/utils/is-electron"], function (_exports, _isElectron) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isElectron() {
    return (0, _isElectron.default)();
  });

  _exports.default = _default;
});