define("@fleetbase/console/router", ["exports", "@fleetbase/console/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor() {
      super(...arguments);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('auth', function () {
      this.route('login', {
        path: '/'
      });
      this.route('logout');
      this.route('forgot-password');
      this.route('reset-password', {
        path: '/reset-password/:id'
      });
    });
    this.route('console', {
      path: '/'
    }, function () {
      this.route('home', {
        path: '/'
      });
      this.mount('fleet-ops');
      this.mount('developers-console');
      this.mount('iam');
      this.mount('storefront');
      this.route('account', function () {
        this.route('settings');
        this.route('profile', {
          path: '/'
        });
        this.route('billing');
        this.route('notifications');
      });
      this.route('extensions', function () {
        this.route('installed');
      });
    });
    this.route('onboard', function () {
      this.route('hello', {
        path: '/'
      });
      this.route('verify-email');
      this.route('activate-services');
    });
    this.route('invite', {
      path: 'join'
    }, function () {
      this.route('for-network', {
        path: '/network/:public_id'
      });
      this.route('for-driver', {
        path: '/fleet/:public_id'
      });
      this.route('for-user', {
        path: '/org/:public_id'
      });
    });
    this.route('v3ui');
  });
});