define("@fleetbase/console/models/zone", ["exports", "@ember-data/model", "date-fns", "@fleetbase/console/utils/auto-serialize"], function (_exports, _model, _dateFns, _autoSerialize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Zone = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.belongsTo)('service-area'), _dec5 = (0, _model.attr)('polygon'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('date'), _dec10 = (0, _model.attr)('date'), _dec11 = (0, _model.attr)('date'), _dec12 = Ember.computed('border.coordinates', 'isNew'), _dec13 = Ember.computed('locations'), _dec14 = Ember.computed('locations'), _dec15 = Ember.computed('updated_at'), _dec16 = Ember.computed('updated_at'), _dec17 = Ember.computed('updated_at'), _dec18 = Ember.computed('created_at'), _dec19 = Ember.computed('created_at'), _dec20 = Ember.computed('created_at'), (_class = class Zone extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "service_area_uuid", _descriptor, this);

      _initializerDefineProperty(this, "name", _descriptor2, this);

      _initializerDefineProperty(this, "description", _descriptor3, this);

      _initializerDefineProperty(this, "service_area", _descriptor4, this);

      _initializerDefineProperty(this, "border", _descriptor5, this);

      _initializerDefineProperty(this, "color", _descriptor6, this);

      _initializerDefineProperty(this, "stroke_color", _descriptor7, this);

      _initializerDefineProperty(this, "status", _descriptor8, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor9, this);

      _initializerDefineProperty(this, "created_at", _descriptor10, this);

      _initializerDefineProperty(this, "updated_at", _descriptor11, this);
    }

    get locations() {
      var _this$border2, _this$border2$coordin;

      if (this.isNew) {
        var _this$border;

        return ((_this$border = this.border) === null || _this$border === void 0 ? void 0 : _this$border.coordinates) ?? [];
      }

      const coordinates = ((_this$border2 = this.border) === null || _this$border2 === void 0 ? void 0 : (_this$border2$coordin = _this$border2.coordinates[0]) === null || _this$border2$coordin === void 0 ? void 0 : _this$border2$coordin.coordinates) ?? [];
      return coordinates.map(coord => coord.reverse());
    }

    get firstCoordinatePair() {
      return this.locations[0] ?? [0, 0];
    }

    get centerCoordinates() {
      const x = this.locations.map(xy => xy[0]);
      const y = this.locations.map(xy => xy[1]);
      const cx = (Math.min(...x) + Math.max(...x)) / 2;
      const cy = (Math.min(...y) + Math.max(...y)) / 2;
      return [cx, cy];
    }

    get updatedAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }

    get updatedAt() {
      return (0, _dateFns.format)(this.updated_at, 'PPP p');
    }

    get updatedAtShort() {
      return (0, _dateFns.format)(this.updated_at, 'PP');
    }

    get createdAgo() {
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }

    get createdAt() {
      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }

    get createdAtShort() {
      return (0, _dateFns.format)(this.created_at, 'PP');
    }

    toJSON() {
      return (0, _autoSerialize.default)(this, ['serviceArea']);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "service_area_uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "service_area", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "border", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "color", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stroke_color", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "locations", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "locations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "firstCoordinatePair", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "firstCoordinatePair"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "centerCoordinates", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "centerCoordinates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAtShort", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtShort", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtShort"), _class.prototype)), _class));
  _exports.default = Zone;
});