define("@fleetbase/console/components/modals/point-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <LeafletMap class="rounded-md shadow-md map-height-base" @lat={{@options.latitude}} @lng={{@options.longitude}} @zoom={{12}} as |layers|>
              <layers.tile @url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
  
              <layers.marker @icon={{@options.icon}} @location={{point-to-coordinates @options.location}} as |marker|>
                  <marker.popup @maxWidth="500" @minWidth="180">
                      {{#if @options.popupText}}
                          <div>{{@options.popupText}}</div>
                      {{/if}}
                      <div>{{format-point @options.location}}</div>
                  </marker.popup>
              </layers.marker>
          </LeafletMap>
      </div>
  </Ui::Modal>
  */
  {
    "id": "HO0//xBy",
    "block": "{\"symbols\":[\"layers\",\"marker\",\"@options\",\"@modalIsOpened\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,4],[32,3],[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[8,\"leaflet-map\",[[24,0,\"rounded-md shadow-md map-height-base\"]],[[\"@lat\",\"@lng\",\"@zoom\"],[[32,3,[\"latitude\"]],[32,3,[\"longitude\"]],12]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"tile\"]],[],[[\"@url\"],[\"https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png\"]],null],[2,\"\\n\\n            \"],[8,[32,1,[\"marker\"]],[],[[\"@icon\",\"@location\"],[[32,3,[\"icon\"]],[30,[36,0],[[32,3,[\"location\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,[32,2,[\"popup\"]],[],[[\"@maxWidth\",\"@minWidth\"],[\"500\",\"180\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,3,[\"popupText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[10,\"div\"],[12],[1,[32,3,[\"popupText\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                    \"],[10,\"div\"],[12],[1,[30,[36,2],[[32,3,[\"location\"]]],null]],[13],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n            \"]],\"parameters\":[2]}]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"point-to-coordinates\",\"if\",\"format-point\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/point-map.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});