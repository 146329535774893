define("@fleetbase/console/templates/console", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tkjoi3Fx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"next/app-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"next/view-header\",[],[[\"@user\",\"@organizations\",\"@onInvalidateSession\",\"@onAction\"],[[32,0,[\"user\"]],[32,0,[\"organizations\"]],[30,[36,0],[[32,0,[\"invalidateSession\"]]],null],[30,[36,0],[[32,0,[\"routeAction\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"ember-wormhole\",[],[[\"@to\"],[\"view-header-actions\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"trial-status-badge\",[],[[\"@badgeClass\"],[\"mr-2\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"next/view-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"next/sidebar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"px-2 pt-3\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,\"role\",\"menu\"],[14,1,\"sidebar-menu-items\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"next/view-section\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[\"isMobile\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"next/mobile-navbar\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"-outlet\",\"component\",\"media\",\"if\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/templates/console.hbs"
    }
  });

  _exports.default = _default;
});