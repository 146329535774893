define("@fleetbase/console/controllers/console", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ConsoleController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ConsoleController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor2, this);

      _initializerDefineProperty(this, "loader", _descriptor3, this);

      _initializerDefineProperty(this, "fetch", _descriptor4, this);

      _initializerDefineProperty(this, "notifications", _descriptor5, this);

      _initializerDefineProperty(this, "organizations", _descriptor6, this);
    }

    /**
     * Action to route an action
     *
     * @void
     */
    routeAction(actionName) {
      if (typeof this[actionName] === 'function') {
        for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          params[_key - 1] = arguments[_key];
        }

        this[actionName](...params);
      }
    }
    /**
     * Action to invalidate and log user out
     *
     * @void
     */


    invalidateSession() {
      this.session.invalidateWithLoader();
    }
    /**
     * Action to invalidate and log user out
     *
     * @void
     */


    createOrJoinOrg() {
      const currency = this.currentUser.currency;
      const country = this.currentUser.country;
      this.modalsManager.show('modals/create-or-join-org', {
        title: 'Create or join a organization',
        acceptButtonText: 'Confirm',
        acceptButtonIcon: 'check',
        acceptButtonIconPrefix: 'fas',
        action: 'join',
        next: null,
        name: null,
        decription: null,
        phone: null,
        currency,
        country,
        timezone: null,
        confirm: modal => {
          modal.startLoading();
          const {
            action,
            next,
            name,
            description,
            phone,
            currency,
            country,
            timezone
          } = modal.getOptions();

          if (action === 'join') {
            return this.fetch.post('auth/join-organization', {
              next
            }).then(() => {
              this.fetch.flushRequestCache('auth/organizations');
              this.notifications.success('You have joined a new organization!');
              setTimeout(() => {
                window.location.reload();
              }, 900);
            });
          }

          return this.fetch.post('auth/create-organization', {
            name,
            description,
            phone,
            currency,
            country,
            timezone
          }).then(() => {
            this.fetch.flushRequestCache('auth/organizations');
            this.notifications.success('You have created a new organization!');
            setTimeout(() => {
              window.location.reload();
            }, 900);
          });
        }
      });
    }
    /**
     * Confirm prompt for user to switch organization
     *
     * @void
     */


    switchOrganization(organization) {
      this.modalsManager.confirm({
        title: `Are you sure you want to switch organization to ${organization.name}?`,
        body: `By confirming your account will remain logged in, but your primary organization will be switched.`,
        acceptButtonText: `Yes, I want to switch organization`,
        acceptButtonScheme: 'primary',
        confirm: modal => {
          modal.startLoading();
          return this.fetch.post('auth/switch-organization', {
            next: organization.uuid
          }).then(() => {
            this.fetch.flushRequestCache('auth/organizations');
            this.notifications.success('You have switched organizations');
            setTimeout(() => {
              window.location.reload();
            }, 900);
          }).catch(error => {
            this.notifications.serverError(error);
          });
        }
      });
    }
    /**
     * Track the users visit to a service.
     *
     * @param {String} routeName
     */


    trackServiceVisit(routeName) {
      const serviceName = Ember.String.classify(routeName.replace(`console.`, ``));
      this.currentUser.trackUserVisit(routeName, {
        serviceName
      });
    }
    /**
     * Get the currently authenticated user
     *
     * @var {Model}
     */


    get user() {
      return this.currentUser.user;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loader", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "organizations", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "routeAction", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "routeAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "invalidateSession", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "invalidateSession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createOrJoinOrg", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "createOrJoinOrg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchOrganization", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "switchOrganization"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackServiceVisit", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "trackServiceVisit"), _class.prototype)), _class));
  _exports.default = ConsoleController;
});