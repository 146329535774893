define("@fleetbase/console/services/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoaderService = (_dec = Ember._tracked, (_class = class LoaderService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "routesLoaded", _descriptor, this);
    }

    showOnCondition(target) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let condition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      const {
        loadingMessage,
        opacity
      } = options;

      if (typeof condition === 'function') {
        condition = condition();
      }

      if (condition) {
        this.showLoader(target, loadingMessage, opacity);
      }
    }

    showOnInitialTransition(transition, target) {
      let loadingMessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Loading...';
      let opacity = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0.75;
      const route = transition.to.name;
      const isSameRoute = transition.from ? transition.to.name === transition.from.name : false;

      if (!this.routesLoaded.includes(route) || !isSameRoute) {
        if (document.querySelectorAll(`.overloader`).length > 0) {
          return;
        }

        this.showLoader(target, loadingMessage, opacity);
        transition.finally(() => {
          this.removeLoader(target);
        });
      }

      this.routesLoaded.pushObject(route);
    }
    /**
     * Creates an HTML element node for a loading overlay with a message.
     *
     * @param {String|HTMLElement} targetSelector
     * @param {String} loadingMessage
     * @return {HTMLElement} loader
     */


    showLoader(targetSelector) {
      var _document$body, _document$body$datase;

      let loadingMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Loading...';
      let opacity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.75;
      let target = typeof targetSelector === 'string' ? document.querySelector(targetSelector) : targetSelector;

      if (!target) {
        return;
      }

      const isDarkMode = ((_document$body = document.body) === null || _document$body === void 0 ? void 0 : (_document$body$datase = _document$body.dataset) === null || _document$body$datase === void 0 ? void 0 : _document$body$datase.theme) === 'dark';
      target.style.position = 'relative';
      let loader = document.createElement('div');
      loader.classList.add('overloader');
      loader.style.backgroundColor = isDarkMode ? `rgba(37, 47, 63, ${opacity})` : `rgba(249, 250, 251, ${opacity})`;
      loader.innerHTML = `<div class="flex items-center justify-center text-center">
            <div>
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" role="img" focusable="false" aria-hidden="true" data-icon="spinner-third" data-prefix="fad" id="ember240" class="svg-inline--fa fa-spinner-third fa-w-16 fa-spin ember-view text-sky-500 fa-spin-800ms mr-3"><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 0 1-6.94-19.2A184 184 0 1 1 256 72c5.89 0 11.71.29 17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16 16 0 0 1 15.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111 248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23 10.55-22 6.11z"></path><path class="fa-primary" fill="currentColor" d="M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11 7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 0 1-25 108.24c-4 8.17-14.37 11-22.26 6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 0 0 440 256c0-96.49-74.27-175.63-168.77-183.38z"></path></g>
                </svg>
            </div>

            <span class="font-semibold text-gray-700 dark:text-gray-100 test-xs md:text-sm">${loadingMessage}</span>
        </div>`;
      target.appendChild(loader);
      return loader;
    }

    show() {
      let loadingMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Loading...';
      let opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.75;
      return this.showLoader(document.body, loadingMessage, opacity);
    }
    /**
     * Creates an HTML element node for a loading overlay with a message.
     *
     * @param {String|HTMLElement} targetSelector
     * @return {Service} this
     */


    removeLoader(targetSelector) {
      const target = typeof targetSelector === 'string' ? document.querySelector(targetSelector) : targetSelector;

      const removeStyle = (el, styleProperty) => {
        if (el.style.removeProperty) {
          el.style.removeProperty(styleProperty);
        } else {
          el.style.removeAttribute(styleProperty);
        }
      };

      if (!target) {
        return;
      }

      if (target.classList.contains('overloader')) {
        target.remove();
        return this;
      }

      const loader = target.querySelector('.overloader');

      if (!loader) {
        return;
      }

      removeStyle(target, 'position');
      target.removeChild(loader);
      return this;
    }
    /**
     * Removes the all loader instances.
     *
     * @return {Service} this
     */


    remove() {
      let delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      const loaders = document.querySelectorAll(`.overloader`);
      setTimeout(() => {
        loaders.forEach(loader => {
          loader.remove();
        });
      }, delay);
      return this;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "routesLoaded", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = LoaderService;
});