define("@fleetbase/console/components/modals/clone-config-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modal @modalIsOpened={{@modalIsOpened}} @options={{@options}} @confirm={{@onConfirm}} @decline={{@onDecline}}>
      <div class="modal-body-container">
          <Ui::InputGroup @name="Config Name" @value={{@options.name}} />
          <Ui::InputGroup @name="Config Description" @value={{@options.description}} />
      </div>
  </Ui::Modal>
  */
  {
    "id": "1h3LnZ0W",
    "block": "{\"symbols\":[\"@modalIsOpened\",\"@options\",\"@onConfirm\",\"@onDecline\"],\"statements\":[[8,\"ui/modal\",[],[[\"@modalIsOpened\",\"@options\",\"@confirm\",\"@decline\"],[[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body-container\"],[12],[2,\"\\n        \"],[8,\"ui/input-group\",[],[[\"@name\",\"@value\"],[\"Config Name\",[32,2,[\"name\"]]]],null],[2,\"\\n        \"],[8,\"ui/input-group\",[],[[\"@name\",\"@value\"],[\"Config Description\",[32,2,[\"description\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/modals/clone-config-form.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});