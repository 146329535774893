define("@fleetbase/console/utils/get-routing-host", ["exports", "@fleetbase/console/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getRoutingHost;
  _exports.isRoutingInCountry = void 0;

  // import { getOwner } from '@ember/application';
  const isRoutingInCountry = function (country, payload) {
    let waypoints = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

    // const owner = getOwner(this);
    // const currentUser = owner.lookup(`service:current-user`);
    // const whois = currentUser.getOption('whois');
    if (Ember.isBlank(payload)) {
      payload = {};
    }

    let countryCode = null;

    if (Ember.get(payload, 'pickup.country') === country || Ember.get(payload, 'dropoff.country') === country) {
      countryCode = country;
    }

    if (Ember.isArray(waypoints) && !Ember.isBlank(waypoints === null || waypoints === void 0 ? void 0 : waypoints.firstObject) && Ember.get(waypoints === null || waypoints === void 0 ? void 0 : waypoints.firstObject, 'place.country') === country) {
      countryCode = country;
    }

    return countryCode === country;
  };

  _exports.isRoutingInCountry = isRoutingInCountry;

  function getRoutingHost(payload) {
    var _config$osrm, _config$osrm$servers, _config$osrm2, _config$osrm2$servers;

    let waypoints = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const isRoutingInCanada = isRoutingInCountry('CA', payload, waypoints);
    const isRoutingInUSA = isRoutingInCountry('US', payload, waypoints);

    if (isRoutingInCanada && typeof ((_config$osrm = _environment.default.osrm) === null || _config$osrm === void 0 ? void 0 : (_config$osrm$servers = _config$osrm.servers) === null || _config$osrm$servers === void 0 ? void 0 : _config$osrm$servers.ca) === 'string') {
      return _environment.default.osrm.servers.ca;
    }

    if (isRoutingInUSA && typeof ((_config$osrm2 = _environment.default.osrm) === null || _config$osrm2 === void 0 ? void 0 : (_config$osrm2$servers = _config$osrm2.servers) === null || _config$osrm2$servers === void 0 ? void 0 : _config$osrm2$servers.us) === 'string') {
      return _environment.default.osrm.servers.us;
    }

    return _environment.default.osrm.host;
  }
});