define("@fleetbase/console/utils/console-url", ["exports", "@fleetbase/console/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = consoleUrl;

  const queryString = params => Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

  function consoleUrl() {
    let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let subdomain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'console';
    let host = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'fleetbase.io';
    let url = `https://${subdomain}.`;
    let urlParams = !Ember.isBlank(queryParams) ? queryString(queryParams) : '';

    if (['qa', 'staging'].includes(_environment.default.environment)) {
      url += `${_environment.default.environment}.`;
    }

    if (host === 'fleetbase.io' && ['local', 'development'].includes(_environment.default.environment)) {
      url += 'fleetbase.engineering';
    } else {
      url += host;
    }

    url += `/${path}`;

    if (urlParams) {
      url += `?${urlParams}`;
    }

    return url;
  }
});