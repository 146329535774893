define("@fleetbase/console/utils/range", ["exports", "@fleetbase/console/utils/is-numeric", "@fleetbase/console/utils/is-letter"], function (_exports, _isNumeric, _isLetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._range = void 0;
  _exports.default = range;

  const _range = (start, end) => {
    return [...Array(end - start + 1).keys()].map(i => i + start);
  };

  _exports._range = _range;

  function range(start, end) {
    if ((0, _isNumeric.default)(start) && (0, _isNumeric.default)(end)) {
      return _range(start, end);
    }

    if ((0, _isLetter.default)(start) && (0, _isLetter.default)(end)) {
      return [...String.fromCharCode(..._range(start.charCodeAt(0), end.charCodeAt(0)))];
    }

    return _range(start, end);
  }
});