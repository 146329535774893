define("@fleetbase/console/helpers/is-waypoint-record", ["exports", "@fleetbase/console/models/waypoint"], function (_exports, _waypoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isWaypointRecord(_ref) {
    let [record] = _ref;
    return record instanceof _waypoint.default;
  });

  _exports.default = _default;
});