define("@fleetbase/console/components/full-calendar", ["exports", "@glimmer/component", "@fullcalendar/core", "@fullcalendar/daygrid", "@fullcalendar/interaction"], function (_exports, _component, _core, _daygrid, _interaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class="ui-full-calendar" {{did-insert (fn this.create)}} {{will-destroy (fn this.destroy)}}></div>
  
  {{yield}}
  */
  {
    "id": "tslZpCtR",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[17,1],[24,0,\"ui-full-calendar\"],[4,[38,1],[[30,[36,0],[[32,0,[\"create\"]]],null]],null],[4,[38,2],[[30,[36,0],[[32,0,[\"destroy\"]]],null]],null],[12],[13],[2,\"\\n\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "@fleetbase/console/components/full-calendar.hbs"
    }
  });

  let FullCalendarComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class FullCalendarComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "calendar", _descriptor, this);

      _initializerDefineProperty(this, "listeners", _descriptor2, this);

      _initializerDefineProperty(this, "events", _descriptor3, this);
    }

    create(element) {
      let events = this.args.events || [];
      this.calendar = new _core.Calendar(element, {
        events,
        plugins: [_daygrid.default, _interaction.default],
        eventStartEditable: this.args.eventStartEditable || true
      });
      this.calendar.render();
      this.listenForEvents(this.calendar);
    }

    destroy() {
      if (this.calendar) {
        this.calendar.destroy();
      }
    }

    onDrop() {
      this.trigger('onDrop', arguments);
    }

    onEventClick() {
      this.trigger('onEventClick', arguments);
    }

    onEventDragStop() {
      this.trigger('onEventDragStop', arguments);
    }

    onEventDrop() {
      this.trigger('onEventDrop', arguments);
    }

    onEventAdd() {
      this.trigger('onEventAdd', arguments);
    }

    onEventChange() {
      this.trigger('onEventChange', arguments);
    }

    onEventReceive() {
      this.trigger('onEventReceive', arguments);
    }

    onDateClick() {
      this.trigger('onDateClick', arguments);
    }

    trigger(eventName) {
      let eventArguments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      if (typeof this.args[eventName] === 'function') {
        this.args[eventName](...eventArguments);
      }
    }

    listenForEvents(calendar) {
      let listeners = this.getCalendarEventListeners();

      for (let i = 0; i < listeners.length; i++) {
        let {
          eventName,
          handlerName
        } = listeners[i];
        calendar.on(eventName, this[handlerName].bind(this));
      }
    }

    getCalendarEventListeners() {
      let events = this.events;
      let listeners = [];

      for (let i = 0; i < events.length; i++) {
        let eventName = events[i];
        let handlerName = `on${Ember.String.classify(eventName)}`;
        let event = {
          eventName,
          handlerName
        };

        if (typeof this[handlerName] === 'function') {
          listeners.pushObject(event);
        }
      }

      this.listeners = listeners;
      return listeners;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "calendar", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "listeners", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "events", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['dateClick', 'drop', 'eventReceive', 'eventClick', 'eventDragStop', 'eventDrop', 'eventAdd', 'eventChange', 'eventRemove'];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "create", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "create"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroy", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "destroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDrop", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onDrop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEventClick", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onEventClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEventDragStop", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onEventDragStop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEventDrop", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onEventDrop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEventAdd", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onEventAdd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEventChange", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onEventChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEventReceive", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onEventReceive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDateClick", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onDateClick"), _class.prototype)), _class));
  _exports.default = FullCalendarComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FullCalendarComponent);
});