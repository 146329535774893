define("@fleetbase/console/helpers/is-not-facilitator-supported-place", ["exports", "fleet-ops/helpers/is-not-facilitator-supported-place"], function (_exports, _isNotFacilitatorSupportedPlace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isNotFacilitatorSupportedPlace.default;
    }
  });
  Object.defineProperty(_exports, "isNotFacilitatorSupportedPlace", {
    enumerable: true,
    get: function () {
      return _isNotFacilitatorSupportedPlace.isNotFacilitatorSupportedPlace;
    }
  });
});