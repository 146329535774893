define("@fleetbase/console/utils/is-facilitator-supported-place", ["exports", "fleet-ops/utils/is-facilitator-supported-place"], function (_exports, _isFacilitatorSupportedPlace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isFacilitatorSupportedPlace.default;
    }
  });
});