define("@fleetbase/console/components/settings-window", ["exports", "fleet-ops/components/settings-window"], function (_exports, _settingsWindow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _settingsWindow.default;
    }
  });
});