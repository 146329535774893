define("@fleetbase/console/helpers/safe-dasherize", ["exports", "@fleetbase/ui/helpers/safe-dasherize"], function (_exports, _safeDasherize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _safeDasherize.default;
    }
  });
  Object.defineProperty(_exports, "safeDasherize", {
    enumerable: true,
    get: function () {
      return _safeDasherize.safeDasherize;
    }
  });
});