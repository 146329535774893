define("@fleetbase/console/utils/auto-serialize", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = autoSerialize;

  const _isEmpty = value => {
    let empty = Ember.isEmpty(value);

    if (empty) {
      return empty;
    } // edge case for empty object {}


    if (typeof value === 'object' && Object.values(value).length === 0) {
      return true;
    }

    return empty;
  };

  const invoke = function (context, method) {
    if (typeof context.method === 'function') {
      for (var _len = arguments.length, params = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        params[_key - 2] = arguments[_key];
      }

      return context.method(...params);
    }

    return null;
  };

  const serialize = model => {
    let serializerMethods = ['toJSON', 'toJson', 'serialize'];

    for (let i = 0; i < serializerMethods.length; i++) {
      const serializer = serializerMethods.objectAt(i);
      const serialized = invoke(model, serializer);

      if (!_isEmpty(serialized)) {
        return serialized;
      }
    }

    return autoSerialize(model);
    ;
  };

  function autoSerialize(model) {
    let except = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    if (Ember.isArray(model)) {
      return model.map(record => autoSerialize(record, except));
    }

    if (!(model instanceof _model.default)) {
      return {};
    } // hacky patch


    const modelName = Ember.get(model, '_internalModel.modelName');

    if (modelName === 'fleet') {
      except.push('drivers');
    }

    if (modelName === 'zone') {
      except.push('service_area');
    }

    const serialized = {}; // set uuid from id

    serialized.id = model.id;
    serialized.uuid = model.id; // serialize attributes

    model.eachAttribute(attr => {
      if (except.includes(attr)) {
        return;
      }

      serialized[attr] = model[attr];
    }); // serialize relationships

    model.eachRelationship((attr, descriptor) => {
      const {
        kind
      } = descriptor;

      if (except.includes(attr)) {
        return;
      }

      if (_isEmpty(model.get(attr))) {
        serialized[attr] = null;
        return;
      }

      if (kind === 'hasMany') {
        serialized[attr] = model[attr].toArray().map(instance => serialize(instance));
      } else {
        serialized[attr] = serialize(model[attr]);
      }
    });
    return serialized;
  }
});